export const normalizeUrlAndExtractParams = url => {
  const segments = {}
  const parts = url.split('/').filter(Boolean) // Split by '/' and remove empty segments

  const normalizedParts = parts.map((part, index) => {
    if (/^\d+$/.test(part)) {
      // If the part is a number, use the preceding segment as the key
      const key = parts[index - 1] || `param${index}`
      segments[key] = part // Dynamically assign key-value pair
      return '{id}' // Replace with placeholder
    }
    return part // Keep static segments as-is
  })

  const normalizedUrl = `/${normalizedParts.join('/')}`

  return {
    normalizedUrl,
    params: segments,
  }
}
